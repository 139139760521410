// everyPage.js
export default function everyPage() {
    console.log('everypage try load');
    let inited = false;
    let wishListInstance = null; // Переменная для хранения экземпляра ShopaholicWishList
    if(inited == false){
        startFrontController();

    }

    function startFrontController() {
        console.log('everypage');
        inited = true;
        // AOS.init();
        // setupBurgerMenu();
        // setupSearchHandlers();
        // setupCustomSelects();
        // setupModals();
        // setupDropdown();
        // setupRegistrationFormValidation();
        setupHeaderAnimation();
        toggleTypeRegistration();

        import('./userBar').then(({ default: UserBar }) => {
            new UserBar();
        }).catch(error => console.error('Ошибка загрузки UserBar:', error));

        // Динамический импорт класса WishListAdd
        // import('./shopaholicTrash/shopaholic-wish-list/ShopaholicWishList').then(({ default: WishListAdd }) => {
        //     if (!wishListInstance) { // Проверка, инициализирован ли уже WishList
        //         wishListInstance = new ShopaholicWishList();
        //     } else {
        //         wishListInstance.init();
        //     }
        // }).catch(error => console.error('Ошибка загрузки WishListAdd:', error));

        // Динамический импорт класса wishProduct
        // import('./wishProduct').then(({ default: wishProduct }) => {
        //     new wishProduct();
        // }).catch(error => console.error('Ошибка загрузки wishProduct:', error));


        import('./ShopaholicCartManager').then(({ default: ShopaholicCartManager }) => {
            const cartManager = new ShopaholicCartManager();
            console.log('ShopaholicCartManager init');
            cartManager.init();
        }).catch(error => console.error('Ошибка загрузки ShopaholicCartManager:', error));


// Скролл к #content с задержкой 3 секунды
        setTimeout(scrollToContent, 300);

        function scrollToContent() {
            const contentElement = document.querySelector('#content');
            if (contentElement) {
                contentElement.scrollIntoView({ behavior: 'smooth' });
            }
        }

        function toggleTypeRegistration() {
            const individualRadio = document.querySelector('input[name="property[type]"][value="0"]');
            const legalRadio = document.querySelector('input[name="property[type]"][value="1"]');
            const organizationField = document.getElementById('organization').parentElement;
            const innField = document.getElementById('inn').parentElement;

            // Изначально скрываем поля для юридического лица
            organizationField.style.display = 'none';
            innField.style.display = 'none';

            // Обработчик для переключения радиокнопок
            function toggleFields() {
                if (legalRadio.checked) {
                    organizationField.style.display = 'flex';
                    innField.style.display = 'flex';

                    // Добавляем атрибут required
                    document.getElementById('organization').setAttribute('required', 'required');
                    document.getElementById('inn').setAttribute('required', 'required');
                } else {
                    organizationField.style.display = 'none';
                    innField.style.display = 'none';

                    // Убираем атрибут required
                    document.getElementById('organization').removeAttribute('required');
                    document.getElementById('inn').removeAttribute('required');
                }
            }

            // При смене значения запускаем обработчик
            individualRadio.addEventListener('change', toggleFields);
            legalRadio.addEventListener('change', toggleFields);

            // Применяем логику при загрузке страницы
            toggleFields();
        }

    }

// function setupBurgerMenu() {
//     const burgers = document.querySelectorAll('.navbar-burger');
//     const menus = document.querySelectorAll('.navbar-menu');
//
//     burgers.forEach((burger, index) => {
//         burger.addEventListener('click', () => {
//             menus[index].classList.toggle('hidden');
//         });
//     });
//
//     const closes = document.querySelectorAll('.navbar-close');
//     const backdrops = document.querySelectorAll('.navbar-backdrop');
//
//     const toggleMenu = () => {
//         menus.forEach(menu => menu.classList.toggle('hidden'));
//     };
//
//     closes.forEach(close => close.addEventListener('click', toggleMenu));
//     backdrops.forEach(backdrop => backdrop.addEventListener('click', toggleMenu));
//
//     // Добавляем обработчик для якорных ссылок в меню
//     const anchorLinks = document.querySelectorAll('.navbar-menu a[href^="/#"]');
//     anchorLinks.forEach(anchor => {
//         anchor.addEventListener('click', () => {
//             menus.forEach(menu => menu.classList.add('hidden'));
//         });
//     });
// }


// function setupSearchHandlers() {
//     setupSearchHandler('searchButton', 'searchBox', 'logotype');
//  }

    function setupSearchHandler(buttonId, boxId, logotypeId, logotypeSmallId = null) {
        const searchButton = document.getElementById(buttonId);
        const searchBox = document.getElementById(boxId);
        const logotype = document.querySelector(`.${logotypeId}`);
        const logotypeSmall = logotypeSmallId ? document.querySelector(`.${logotypeSmallId}`) : null;

        searchButton.addEventListener('click', event => {
            searchBox.style.display = 'block';
            searchButton.style.display = 'none';
            logotype.style.display = 'none';
            if (logotypeSmall) logotypeSmall.style.opacity = '0';
            event.stopPropagation();
        });

        searchBox.addEventListener('click', event => event.stopPropagation());

        document.addEventListener('click', () => {
            if (searchBox.style.display === 'block') {
                searchBox.style.display = 'none';
                searchButton.style.display = 'block';
                logotype.style.display = 'block';
                if (logotypeSmall) logotypeSmall.style.opacity = '1';
            }
        });
    }
//
// function setupCustomSelects() {
//     document.querySelectorAll('.select__head').forEach(head => {
//         head.addEventListener('click', function() {
//             const open = head.classList.contains('open');
//             document.querySelectorAll('.select__head').forEach(h => h.classList.remove('open'));
//             document.querySelectorAll('.select__list').forEach(list => list.style.display = 'none');
//             if (!open) {
//                 head.classList.add('open');
//                 head.nextElementSibling.style.display = 'block';
//             }
//         });
//     });
//
//     document.querySelectorAll('.select__item').forEach(item => {
//         item.addEventListener('click', function() {
//             const select = item.closest('.select');
//             const head = select.querySelector('.select__head');
//             const list = select.querySelector('.select__list');
//             head.textContent = item.textContent;
//             select.querySelector('input').value = item.textContent;
//             list.style.display = 'none';
//             head.classList.remove('open');
//         });
//     });
//
//     document.addEventListener('click', event => {
//         if (!event.target.closest('.select')) {
//             document.querySelectorAll('.select__head').forEach(head => head.classList.remove('open'));
//             document.querySelectorAll('.select__list').forEach(list => list.style.display = 'none');
//         }
//     });
// }

// function setupModals() {
//     setupModalHandler('registration', 'modal-registration');
//     setupModalHandler('login', 'modal-login');
//     setupModalHandler('tour', 'modal-tour');
//
//     document.querySelectorAll('.modal_area').forEach(element => {
//         element.addEventListener('click', () => closeAllModals());
//     });
//
//     document.querySelectorAll('.modal_close').forEach(element => {
//         element.addEventListener('click', () => closeAllModals());
//     });
// }

//
// function setupModalHandler(buttonClass, modalClass) {
//     const buttons = document.querySelectorAll(`.${buttonClass}`);
//     const modal = document.querySelector(`.${modalClass}`);
//
//     if (buttons.length === 0) {
//         console.warn(`Buttons with class ${buttonClass} not found.`);
//         return;
//     }
//
//     if (!modal) {
//         console.warn(`Modal with class ${modalClass} not found.`);
//         return;
//     }
//
//     buttons.forEach(element => {
//         element.addEventListener('click', () => {
//
//             modal.classList.toggle('active');
//             console.log(modal);
//             let iframeContainer = document.getElementById('map-iframe-container');
//             if (iframeContainer && !iframeContainer.querySelector('iframe')) {
//                 const iframe = document.createElement('iframe');
//                 iframe.src = "https://yandex.ru/map-widget/v1/?ll=33.075459%2C68.973192&mode=poi&panorama%5Bdirection%5D=297.500000%2C10.000000&panorama%5Bfull%5D=true&panorama%5Bpoint%5D=33.074532%2C68.973170&panorama%5Bspan%5D=102.879809%2C60.000000&poi%5Bpoint%5D=33.074454%2C68.973185&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D137255731601&tab=panorama&z=19.07";
//                 iframe.loading = "lazy";
//                 iframe.frameBorder = "1";
//                 iframe.allowFullscreen = true;
//                 iframe.className = "w-full h-full absolute top-0 left-0";
//                 iframeContainer.appendChild(iframe);
//             }
//         });
//     });
// }



    function closeAllModals() {
        document.querySelectorAll('.modal-registration, .modal-login, .modal-tour').forEach(modal => {
            modal.classList.remove('active');
        });
    }
//
// function setupDropdown() {
//     const dropdownBtn = document.querySelector('.dropdown-btn');
//     const dropdownContent = document.querySelector('.dropdown-content');
//     const dropdownArrow = document.querySelector('.dropdown-arrow');
//     const dropdownItems = document.querySelectorAll('.dropdown-content .tab-btn');
//     const selectedCategory = document.querySelector('.selected-category');
//
//     if (dropdownBtn && dropdownContent && dropdownArrow) {
//         dropdownBtn.addEventListener('click', () => {
//             dropdownContent.classList.toggle('hidden');
//             dropdownArrow.classList.toggle('rotate-180');
//         });
//
//         dropdownItems.forEach(item => {
//             item.addEventListener('click', function() {
//                 dropdownContent.classList.add('hidden');
//                 dropdownArrow.classList.remove('rotate-180');
//                 dropdownBtn.textContent = this.textContent;
//                 selectedCategory.textContent = `Выбрана категория: ${this.textContent}`;
//             });
//         });
//     }
// }

// function setupRegistrationFormValidation() {
//     const registrationForm = document.getElementById('registrationForm');
//     if (registrationForm) {
//         registrationForm.addEventListener('submit', function(event) {
//             const rememberCheckbox = document.getElementById('rememberCheckbox');
//             if (!rememberCheckbox.checked) {
//                 alert('Вы должны согласиться на обработку персональных данных.');
//                 event.preventDefault();
//                 rememberCheckbox.focus();
//                 return false;
//             }
//         });
//     }
// }

    function setupHeaderAnimation() {
        setTimeout(() => {
            const headers = document.querySelectorAll('h2');
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const header = entry.target;
                        header.classList.remove('header-hidden');
                        header.classList.add('type-animation');
                        observer.unobserve(header);
                    }
                });
            }, {
                threshold: 0.5
            });

            headers.forEach(header => {
                header.classList.add('header-hidden');
                observer.observe(header);
            });
        }, 500);
    }

}
