// search.js
export default function search() {
    let searchBtn = document.getElementById('search-panel');

    searchBtn.addEventListener('click', function () {
        import('./shopaholicTrash/shopaholic-search/shopaholic-search')
            .then(({default: ShopaholicSearch}) => {
                const search = new class Search {
                    constructor() {
                        this.searchResultWrapper = 'search-panel__result';
                        this.init();
                    }

                    init() {
                        this.obSearchHelper = new ShopaholicSearch();
                        this.obSearchHelper.setAjaxRequestCallback((obRequest) => {
                            obRequest.update = {'header/search/search-panel-result': `.${this.searchResultWrapper}`};
                            obRequest.loading = this.preLoaderSelector;
                            return obRequest;
                        });
                        this.obSearchHelper.init();
                    }
                }();
            })
            .catch(err => console.error('Ошибка загрузки модуля:', err));
    });
}
