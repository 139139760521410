/**
 * Класс ShopaholicWishList управляет функционалом добавления и удаления товаров в список желаемого.
 *
 * Основные функции:
 *
 * - Инициализация обработчиков событий для добавления и удаления товаров.
 * - Обработка кликов по кнопкам добавления и удаления товаров из списка желаемого.
 * - Отправка AJAX-запросов для обновления списка желаемого.
 * - Обновление счетчика списка желаемого и отображение всплывающих сообщений.
 */

export default class ShopaholicWishList {
    constructor() {
        this.sDefaultButtonClass = '_shopaholic-add-wish-list-button';
        this.sRemoveButtonClass = '_shopaholic-remove-wish-list-button';
        this.sDefaultWrapperClass = '_shopaholic-product-wrapper';
        this.sWrapperSelector = `.${this.sDefaultWrapperClass}`;
        this.sAttributeName = 'data-product-id';
        this.sComponentAddMethod = 'ProductList::onAddToWishList';
        this.sComponentRemoveMethod = 'ProductList::onRemoveFromWishList';
        this.sWishListInfoWrapper = 'userbar__item_wish-list';
        this.obAjaxRequestCallback = null;
        this.cardSelector = 'wish-product';
        this.restoreHiddenNodeSelector = 'wish-list-panel__restore_visually-hidden';
        this.cardHiddenSelector = 'wish-product_hidden';
        this.restoreBtnSelector = 'wish-list-panel__restore-btn';
        this.eventListenersInitialized = false; // Флаг для проверки инициализации событий
        this.init();
    }

    init() {
        if (!this.eventListenersInitialized) {
            this.initAddHandler();
            this.initRemoveHandler();
            this.eventListenersInitialized = true;
        }
    }

    initAddHandler() {
        document.addEventListener('click', this.handleAddClick.bind(this));
    }

    initRemoveHandler() {
        document.addEventListener('click', this.handleRemoveClick.bind(this));
    }

    handleAddClick(event) {
        const target = event.target.closest(`.${this.sDefaultButtonClass}`);
        if (target) {
            target.disabled = true;  // Отключаем кнопку
            const iProductID = this.getProductID(target);
            this.sendAjaxRequest(iProductID, target, this.sComponentAddMethod, (obRequestData) => {
                this.turnOnFavoriteBtn(target);
                obRequestData.update = { 'header/wishListInfo': `.${this.sWishListInfoWrapper}` };
                obRequestData.complete = (response) => {
                    // this.updateWishListInfo();
                    this.updateWishListCounter(response['header/wishListInfo']);
                    this.showFlashMessage('Товар добавлен в избранное', 'success');
                    target.classList.remove(this.sDefaultButtonClass);
                    target.classList.add(this.sRemoveButtonClass);
                    target.disabled = false;  // Включаем кнопку снова
                };
                obRequestData.error = () => {
                    this.turnOffFavoriteBtn(target);
                    target.disabled = false;  // Включаем кнопку снова при ошибке
                };
                return obRequestData;
            });
        }
    }

    handleRemoveClick(event) {
        const target = event.target.closest(`.${this.sRemoveButtonClass}`);
        if (target) {
            target.disabled = true;  // Отключаем кнопку
            const iProductID = this.getProductID(target);
            this.sendAjaxRequest(iProductID, target, this.sComponentRemoveMethod, (obRequestData) => {
                this.turnOffFavoriteBtn(target);
                obRequestData.update = { 'header/wishListInfo': `.${this.sWishListInfoWrapper}` };
                obRequestData.complete = (response) => {
                    // this.updateWishListInfo();
                    this.updateWishListCounter(response['header/wishListInfo']);
                    this.showFlashMessage('Товар удален из избранного', 'error');
                    target.classList.remove(this.sRemoveButtonClass);
                    target.classList.add(this.sDefaultButtonClass);
                    target.disabled = false;  // Включаем кнопку снова
                };
                obRequestData.error = () => {
                    this.turnOnFavoriteBtn(target);
                    target.disabled = false;  // Включаем кнопку снова при ошибке
                };
                return obRequestData;
            });
        }
    }


    sendAjaxRequest(iProductID, obButton, sComponentMethod, callback) {
        let obRequestData = {
            'data': { 'product_id': iProductID }
        };

        if (callback !== null) {
            obRequestData = callback(obRequestData, obButton);
        }

        oc.ajax(sComponentMethod, obRequestData);
    }

    getProductID(obButton) {
        const obProduct = obButton.closest(this.sWrapperSelector);

        if (obProduct) {
            return obProduct.getAttribute(this.sAttributeName);
        }
        return null;
    }

    turnOnFavoriteBtn(obButton) {
        obButton.classList.add('favorite');
    }

    turnOffFavoriteBtn(obButton) {
        obButton.classList.remove('favorite');
    }

    // updateWishListInfo() {
    //     const counterElement = document.querySelector('.wish-list-info__counter');
    //     const infoElement = document.querySelector('.wish-list-info');
    //     if (counterElement && infoElement) {
    //         const count = parseInt(counterElement.textContent, 10);
    //         if (count === 0) {
    //             infoElement.classList.add('hidden');
    //         } else {
    //             infoElement.classList.remove('hidden');
    //         }
    //     }
    // }
    updateWishListCounter(htmlContent) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const newCounter = doc.querySelector('.wish-list-info__counter').textContent;
        document.querySelector('.wish-list-info__counter').textContent = newCounter;
    }
    showFlashMessage(message, type) {
        oc.flashMsg({
            message: message,
            type: type,
            interval: 1
        });
    }
}
