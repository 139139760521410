import everyPage from './everyPage';
import search from './search';
import ShopaholicWishList from "./shopaholicTrash/shopaholic-wish-list/ShopaholicWishList";
import AOS from 'aos';
import 'aos/dist/aos.css';
function initializeScripts() {
    console.log('initializeScripts global');
    new ShopaholicWishList(); // Используем new для создания экземпляра класса
    everyPage();
    search();
    AOS.init({
        offset: 100, // Смещение: анимация начнется за 100px до того, как элемент будет виден
        duration: 800, // Длительность анимации в миллисекундах
        easing: 'ease-in-out', // Плавность анимации
        anchorPlacement: 'top-bottom', // Анимация начнется, когда верх элемента будет на нижней границе экрана
    });

    const scrollableContent = document.querySelector('.custom-scrollbar-container ul');
    const customScrollbar = document.querySelector('.custom-scrollbar');
    const scrollTrail = document.querySelector('.scroll-trail');

    function updateCustomScrollbar() {
        const scrollHeight = scrollableContent.scrollHeight;
        const scrollTop = scrollableContent.scrollTop;
        const clientHeight = scrollableContent.clientHeight;

        // Процент прокрутки
        const scrollPercentage = scrollTop / (scrollHeight - clientHeight);

        // Высота кастомной полосы прокрутки
        const scrollbarHeight = Math.max((clientHeight / scrollHeight) * clientHeight, 30);
        customScrollbar.style.height = `${scrollbarHeight}px`;

        // // Позиция кастомной полосы прокрутки
        // const scrollbarTop = scrollPercentage * (clientHeight - scrollbarHeight);
        // customScrollbar.style.transform = `translateY(${scrollbarTop}px)`;
        //
        // // Обновляем след
        // const trailHeight = scrollbarHeight; // Высота следа равна высоте полосы прокрутки
        // scrollTrail.style.height = `${trailHeight}px`;
        // scrollTrail.style.transform = `translateY(${scrollbarTop}px)`;
        //
        // // Показываем след только если есть прокрутка
        // if (scrollTop > 0) {
        //     scrollTrail.style.opacity = 1; // Показать след
        // } else {
        //     scrollTrail.style.opacity = 0; // Скрыть след
        // }

        // Двигаем фон ленты
        const backgroundPosition = scrollPercentage * 100;
        customScrollbar.style.backgroundPosition = `0 ${backgroundPosition}%`;
    }

    // Обновляем полосу прокрутки при прокручивании содержимого
    scrollableContent.addEventListener('scroll', updateCustomScrollbar);

    // Вызываем при загрузке
    updateCustomScrollbar();



}

document.addEventListener('DOMContentLoaded', () => {
    initializeScripts();
});

// Повторная инициализация при использовании Turbo Router
// document.addEventListener('render', () => {
//     initializeScripts();
// });
